<nz-layout> <nz-content class="contenedor">
    <div class="authContainer responsive-bg"
      [ngClass]="{ 'cui__layout__squaredBorders': isSquaredBorders, 'cui__layout__cardsShadow': isCardShadow, 'cui__layout__borderless': isBorderless, 'white': authPagesColor === 'white', 'gray': authPagesColor === 'gray' }"
      >
      <div [ngClass]="settings.tipoAuth == 0 ? 'containerInner': 'containerInner2'">
        <div [@slideFadeinUp]="routeAnimation(outlet, 'slideFadeinUp')"
          [@slideFadeinRight]="routeAnimation(outlet, 'slideFadeinRight')"
          [@zoomFadein]="routeAnimation(outlet, 'zoomFadein')" [@fadein]="routeAnimation(outlet, 'fadein')">
          <router-outlet #outlet="outlet"></router-outlet> </div>
      </div>
    </div>
  </nz-content> </nz-layout>