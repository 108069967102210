
export const getMenuDataAdmin: any[] = [

  {
    category: true,
    title: 'Usuario administrador',
  },

  {
    title: 'Administración',
    key: 'administracion',
    icon: 'fe fe-users',
    // roles: ['admin'], // set user roles with access to this route
    count: 1,
    children: [
      {
        title: 'Usuarios',
        key: 'usuarios',
        url: '/administracion/usuarios',
      },
      {
        title: 'Rol',
        key: 'role',
        url: '/administracion/roles',
      },
      {
        title: 'Parametros',
        key: 'parametros',
        url: '/administracion/parametros',
      }
    ],
  },

  {
    title: 'Configuración',
    key: 'configuracion',
    icon: 'fe fe-settings',
    // roles: ['admin'], // set user roles with access to this route
    count: 6,
    children: [
      {
        title: 'Fichas',
        key: 'ficha',
        url: '/configuracion/ficha',
      },
      {
        title: 'Modulos',
        key: 'modulo',
        url: '/configuracion/modulo',
      },
      {
        title: 'Configurar Caracterización',
        key: 'preguntas',
        url: '/configuracion/preguntas',
      }
    ],
  },
  {
    title: 'Ingesta De Datos',
    key: 'encuestas',
    icon: 'fe fe-hard-drive',
    // roles: ['admin'], // set user roles with access to this route
    count: 1,
    children: [
      {
        title: 'Editar',
        key: 'listar-encuesta',
        url: '/encuestas/listar-encuesta',
      },
      {
        title: 'Nueva',
        key: 'encuestaVpro',
        url: '/encuestas/encuestaVpro',
      }
    ],
  },
  {
    title: 'Visualización',
    key: 'encuestas',
    icon: 'fe fe-hard-drive',
    // roles: ['admin'], // set user roles with access to this route
    count: 1,
    children: [
      {
        title: 'Tableros',
        key: 'encuestaVpro',
        url: '/encuestas/encuestaVpro',
      }
    ],
  },
  {
    title: 'Intervenciones',
    key: 'intervenciones',
    icon: 'fe fe-hard-drive',
    // roles: ['admin'], // set user roles with access to this route
    count: 1,
    children: [
      {
        title: 'Profesionales De La Salud',
        key: 'profesionales-salud',
        url: '/intervenciones/profesionales-salud',
      },
      {
        title: 'Gestion De Agendas',
        key: 'gestion-agendas',
        url: '/intervenciones/gestion-agendas',
      },
      {
        title: 'Alertas',
        key: 'alertas',
        url: '/intervenciones/alertas',
      },
      {
        title: 'Gestion De Citas',
        key: 'gestion-citas',
        url: '/intervenciones/gestion-citas',
      },
      {
        title: 'Canalizacion De Citas',
        key: 'canalizacion-servicios',
        url: '/intervenciones/canalizacion-servicios',
      },
      {
        title: 'Teleconsultas',
        key: 'teleconsultas',
        url: '/intervenciones/teleconsultas',
      }
    ],
  }

]



export const getMenuDataUser: any[] = [
  {
    category: true,
    title: 'Usuario normal',
  },
  {
    title: 'Escritorio',
    key: 'escritorio',
    icon: 'fe fe-clipboard',
    url: '/dashboard/alpha',
  },
  {
    title: 'Ingesta De Datos',
    key: 'encuestas',
    icon: 'fe fe-hard-drive',
    // roles: ['admin'], // set user roles with access to this route
    count: 1,
    children: [
      {
        title: 'Editar',
        key: 'listar-encuesta',
        url: '/encuestas/listar-encuesta',
      },
      {
        title: 'Nueva',
        key: 'encuestaVpro',
        url: '/encuestas/encuestaVpro',
      }
    ],
  },
]
